import React, { useEffect, useState, useContext, useCallback, useRef } from 'react';
import { fetchDataHelper } from "../utils";
import { UseAuthenticationContext, NarrowScreenContext } from "../context";
import { MsalContext } from "@azure/msal-react";
import { Drawer } from 'rsuite';

export default function EmbeddedDocs(props) {
  const { folder } = props;

  const msalContext = useContext(MsalContext);
  const { useAuthentication } = useContext(UseAuthenticationContext);
  const { isNarrowScreen } = useContext(NarrowScreenContext);

  const [content, setContent] = useState(null);
  const [links, setLinks] = useState(null);
  const [isMenuVisible, setIsMenuVisible] = useState(false);

  // Cloud and local document folders for userManual are different
  let documentFolder;
  if (useAuthentication) { documentFolder = `/boa/documents/${folder}/`; }
  else { documentFolder = `/local/${folder}/MB/`; }

  // If hash (#) is included in the url, use it as the document path to immediately open the specified page in the manual
  const docPath = window.location.hash ? window.location.hash.substring(1) : 'index.html';

  useEffect(() => {
    try {
      fetchDataHelper({
        url: `${documentFolder}index.html`,
        useAuthentication: useAuthentication,
        authContext: msalContext,
        onSuccess: (response) => {
          setLinks(response.data);
        }
      });
    } catch (error) {
      console.error(error.message);
    }
  }, [documentFolder, useAuthentication, msalContext]);

  useEffect(() => {
    if (docPath === 'index.html') {
      setContent(null);
    } else {
      try {
        fetchDataHelper({
          url: `${documentFolder}${docPath}`,
          useAuthentication: useAuthentication,
          authContext: msalContext,
          onSuccess: (response) => setContent(response.data)
        });
      } catch (error) {
        console.error(error.message);
      }
    }
  }, [documentFolder, useAuthentication, msalContext, docPath]);

  // Make the ruuri-draggable-item full width so the iframe can be resized to fit the available space
  useEffect(() => {
    const style = document.createElement('style');
    style.innerHTML = `
      .ruuri-draggable-item {
        width: 100%;
      }

      .ruuri-draggable-grid {
        margin: 0;
      }
    `;
    document.head.appendChild(style);

    // Cleanup function to remove the style when the component is unmounted
    return () => {
      document.head.removeChild(style);
    };
  }, []);

  const styleContent = (iframeDocument) => {
    const theme = localStorage.getItem('theme') || 'light';
    const style = document.createElement('style');
    style.innerHTML = `
      @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');
      body {
        font-family: 'Poppins', sans-serif;
        color: ${theme === 'dark' ? 'white' : 'black'};
        background-color: transparent;
      }
      a {
        text-decoration: none;
        color: ${theme === 'dark' ? '#81b3ea' : 'blue'};
      }
      a.selected-link {
        color: ${theme === 'dark' ?  '#f2ada8' : '#0074be'};
      }
      p {
        margin-bottom: 1.5em;
      }
      #breadcrumbs {
        padding: 0;
        margin: 0;
        font-size: 0;
        list-style: none;
      }
      #breadcrumbs li {
        display: inline-block;
        font-size: 18px;
      }
      #breadcrumbs li::before {
        content: "/";
        display: inline-block;
        padding: 0 6px 0 8px;
        color: white;
      }
      #breadcrumbs li:first-child::before {
        display: none;
      }
    `;
    iframeDocument.head.appendChild(style);
  };

  const removeContent = (iframeDocument) => {
    const selectors = [
      'div.pageSection.group',
      '.page-metadata'
    ];

    selectors.forEach((selector) => {
      iframeDocument.querySelectorAll(selector).forEach((element) => element.remove());
    });

    // Ensure the external stylesheet link is removed after content is fully loaded
    const stylesheetLinks = iframeDocument.querySelectorAll('link[rel="stylesheet"]');
    stylesheetLinks.forEach((link) => {
      if (link.href.includes('styles/site.css')) {
        link.remove();
      }
    });

    // Remove the footer if present
    const footerDiv = iframeDocument.getElementById('footer');
    if (footerDiv) footerDiv.remove();
  };

  const modifyContentLinks = useCallback((iframeDocument) => {
    const links = iframeDocument.querySelectorAll('a');
    links.forEach((link) => {
      try {
        const linkUrl = new URL(link.href, window.location.href);
        const linkHostname = linkUrl.hostname;
        if (linkHostname !== window.location.hostname) {
          link.setAttribute('target', '_blank');
        } else {
          if (window.location.hash === `#${linkUrl.pathname.split("/").pop()}`) {
            link.classList.add('selected-link');
          } else {
            link.addEventListener('click', (event) => {
              event.preventDefault();
              window.location.hash = linkUrl.pathname.substring(linkUrl.pathname.lastIndexOf('/') + 1);

              // Remove the selected-link class from all links
              iframeDocument.querySelectorAll('a.selected-link').forEach((selectedLink) => {
                selectedLink.classList.remove('selected-link');
              });

              // Add the selected-link class to the clicked link
              link.classList.add('selected-link');
            });

            if (useAuthentication) {
              const pathname = linkUrl.pathname;
              const fileName = pathname.substring(pathname.lastIndexOf('/') + 1);
              link.href = `${documentFolder}${fileName}`.replace('//', '/');
            } else {
              if (!link.href.includes(documentFolder)) {
                const pathname = linkUrl.pathname;
                const finalDocumentFolderPart = documentFolder.replace(/^.*\/local\//, '');
                const base = pathname.substring(0, pathname.lastIndexOf('/') + 1);
                const fileName = pathname.substring(pathname.lastIndexOf('/') + 1);
                link.href = `${base}${finalDocumentFolderPart}${fileName}`.replace('//', '/');
              }
            }
          }
        }
      } catch (error) {
        console.error(`Error processing link: ${link.href}`, error);
      }
    });
  }, [documentFolder, useAuthentication]);

  const modifyImagePaths = useCallback((iframeDocument) => {
    const images = iframeDocument.querySelectorAll('img');
    images.forEach((img) => {
      try {
        const imgSrc = new URL(img.src, window.location.href);
        const imgHostname = imgSrc.hostname;
        if (imgHostname !== window.location.hostname) {
          img.src = imgSrc.href;
        } else {
          const pathname = imgSrc.pathname;
          const folderPath = pathname.substring(0, pathname.lastIndexOf('/')).replace('/boa/local/attachments', '');
          const fileName = pathname.substring(pathname.lastIndexOf('/') + 1);
          if (useAuthentication) {
            const relativePath = folderPath.split('/').slice(3).join('/');
            img.src = `${documentFolder}${relativePath}/${fileName}`.replace('//', '/');
          } else {
            img.src = `${process.env.PUBLIC_URL}/${documentFolder}/attachments${folderPath}/${fileName}`.replace('//', '/');
          }
        }
      } catch (error) {
        console.error(`Error processing image: ${img.src}`, error);
      }
    });
  }, [documentFolder, useAuthentication] );

  const filterIframeContent = useCallback((iframeDocument) => {
    styleContent(iframeDocument);
    removeContent(iframeDocument);
    modifyContentLinks(iframeDocument);
    modifyImagePaths(iframeDocument);
  }, [modifyContentLinks, modifyImagePaths]);

  const toggleMenuVisibility = () => {
    setIsMenuVisible(!isMenuVisible);
  };

  const generateLinks = (links) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(links, 'text/html');
    const ulElement = doc.querySelector('ul');
  
    if (!ulElement) return null;

    const createReactElement = (node) => {
      if (node.nodeType === Node.ELEMENT_NODE) {
        const children = Array.from(node.childNodes).map(createReactElement);
        const props = { key: node.outerHTML };
  
        if (node.tagName.toLowerCase() === 'a') {
          props.href = node.getAttribute('href');
          props.style = { display: 'block', padding: '5px 10px', cursor: 'pointer' };
          props.onClick = (event) => {
            event.preventDefault();
            window.location.hash = node.getAttribute('href');
          };
          props.className = window.location.hash === `#${node.getAttribute('href')}` ? 'selected-link' : '';
        }
  
        return React.createElement(node.tagName.toLowerCase(), props, children);
      } else if (node.nodeType === Node.TEXT_NODE) {
        return node.textContent;
      }
    };
  
    return createReactElement(ulElement);
  };

  return (
    <div className='embedded-docs-container'
      style={{
        width: isMenuVisible ? "82%" : "100%",
        float: isMenuVisible ? "right" : "left"
      }}
    >
      {!content && 
        <div className='embedded-docs-content'>
          {generateLinks(links)}
        </div>
      }
      {content && 
        <>
        {!isNarrowScreen && 
          <div className="embedded-docs-menu-toggle">
            <button onClick={toggleMenuVisibility} className='toggle-menu-button'>
              {isMenuVisible ? <i className="fas fa-outdent"></i>: <i className="fas fa-indent"></i>}
            </button>
          </div>
        }
      </>
      }
      {content && 
        <Iframe
          src={`${documentFolder}/index.html`}
          srcDoc={content}
          onLoadContent={filterIframeContent}
        />
      }
       <Drawer 
          placement="left"
          backdrop={false}
          open={isMenuVisible}
          onClose={()=> setIsMenuVisible(!isMenuVisible)}
        >
        <Drawer.Body className="no-padding">
          <div className='embedded-docs-content'>
            {generateLinks(links)}
          </div>
        </Drawer.Body>
      </Drawer>
    </div>
  );
}

/* iframe component that is used to show html files */
const Iframe = ({ src, srcDoc, onLoadContent}) => {
  const iframeRef = useRef(null);

  useEffect(() => {
    const iframe = iframeRef.current;
    const onLoad = () => {
      const iframeDocument = iframe.contentDocument;
      onLoadContent(iframeDocument);
    };

    if (iframe) {
      iframe.addEventListener('load', onLoad);
      return () => iframe.removeEventListener('load', onLoad);
    }
  }, [onLoadContent]);

  return (
    <iframe
      ref={iframeRef}
      src={src}
      srcDoc={srcDoc}
      title="Embedded Documentation"
      className='embedded-docs-iframe'
    />
  );
};