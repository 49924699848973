import React from "react";
import { AndritzBackgroundLogo } from "../img";

export default function AndritzLogo() {
  const version = process.env.REACT_APP_VERSION;
  const year = new Date().getFullYear();

  return (
    <div className="andritz-logo-container">
      <img className="andritz-logo" src={AndritzBackgroundLogo} alt="ANDRITZ" />
      <div className="background-logo-text" id="version">
        Smart BOA v.{version}
        <br />
        © ANDRITZ {year}
      </div>
    </div>
  );
};