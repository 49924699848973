import React, { useState, useContext } from "react";
import Select from "react-select";
import { GlobalTimefilter } from "../timebuttons";
import { getTimeparams } from "../utils";
import { translate } from "../languages";
import { MenuToggleButton, HeaderButton } from "../iconButtons";
import { PdfExportModal } from "../modals";
import { CustomPanelButton } from "../customPanel";
import { Button } from "rsuite";
import {  UseAuthenticationContext } from "../context";

export default function Breadcrumb(props) {
  const {
    activeApp,
    appContent,
    refreshContent,
    onGlobalTimeFilterChange,
    onGlobalResolutionChange,
    visibilitySettings,
    site,
    metakeys,
    togglePanels,
    minimizedPanels,
    unMinimizePanel,
    item,
    site_info,
    toggleMenu,
    collapsed,
    updateGlobalTime,
    panelTimeValues,
    globalResolution,
    isNarrowScreen,
    endpoints,
    showCustomPanelButton,
    resolutions
  } = props;

  const [showPDFModal, togglePDFModal] = useState(false);

  const { useAuthentication } = useContext(UseAuthenticationContext);

  const getMenuToggleButton = () => {
    return (
      <MenuToggleButton
        showMenu={!collapsed}
        onClick={toggleMenu}
      />
    );
  }

  const getTitleAndIcon = () => {
    if (isNarrowScreen) { return null }
    let icon = "fa-space-shuttle";
    let logo;
    let logoPath;
    if (item?.icon) { icon = item.icon; }
    else if (site_info?.logo) { logo = site_info?.logo }
    if (useAuthentication) { logoPath = `/media/logos/${logo}`; }
    else { logoPath = `${process.env.PUBLIC_URL}/local/media/logos/${logo}`; }
    let text = "Smart BOA";
    if (item?.text) { text = item.text; }
    else if (site_info?.text) { text = site_info.text?.split(" [")[0]; }
    return (
      <div className="breadcrumb-current-page ms-4">
        <div className="breadcrumb-icon" id={logo ? "breadlogo" : "breadicon"}>
          {logo
            ? <img height="30px" src={logoPath} alt={logo} />
            : <em className={"fas " + icon}></em>
          }
        </div>
        <div className="breadcrumb-link" id="breadtext">
          {translate(text)}
        </div>
      </div>
    );
  }

  const getMinimizedPanelsSelection = () => {
    let options = [];
    for (let layout of Object.keys(minimizedPanels)) {
      const layoutPanels = {
        label: layout,
        options: minimizedPanels[layout].map(
          panel => ({
            label: translate(panel.label),
            value: { layout: layout, panel: panel.id }
          })
        )
      };
      options.push(layoutPanels);
    }
  
    for (let i = 0; i < options.length; ++i) {
      options[i].label = translate(options[i].label);
    }
  
    return (
      <div className={!isNarrowScreen ? 'left-divider' : ''}>
        <Select
          value={{
            label: translate("Minimized panels"),
            value: ""
          }}
          className="header-selection"
          classNamePrefix="header-select"
          options={options}
          placeholder="Minimized panels"
          onChange={(option) => unMinimizePanel(option.value)}
          isSearchable={false}
        />
      </div>
    );
  }

  const getGlobalTimeFilter = () => {
    let timeButtons = null;
    if (appContent.global_timebuttons) { timeButtons = appContent.global_timebuttons; }
    else { timeButtons = [600, 1800, 3600, 28800, 86400]; }
    let globalResolutionButtons = resolutions;
    if (timeButtons.length > 0) {
      return (
        <div className={!isNarrowScreen ? 'left-divider' : ''}>
          <GlobalTimefilter
            onChange={onGlobalTimeFilterChange}
            buttons={timeButtons}
            resButtons={globalResolutionButtons}
            buttonText="show_global_time_buttons"
            handleChange={(v) => handleTimeChange(v)}
            handleResChange={(v) => onGlobalResolutionChange(v)}
            reset={() => resetPanels()}
            updateGlobalTime={updateGlobalTime}
            panelTimeValues={panelTimeValues}
            globalResolution={globalResolution}
          />
        </div>
      );
    }
  }

  const handleTimeChange = (timeWindow) => {
    let params = getTimeparams(timeWindow);
    let dates = { sdt: params.sdt, edt: params.edt };
    updateGlobalTime({
      sdt: dates.sdt,
      edt: dates.edt,
      timeWindow: timeWindow,
      extraTime: 0,
      skipTimeCounter: 0,
      autoUpdate: true
    })
  }

  const resetPanels = () => {
    let keys = Object.keys(localStorage);
    for (let key of keys) {
      if (key.includes(`${site}-${activeApp}`)) {
        localStorage.removeItem(key);
      }
    }
    refreshContent();
  }

  const getPanelToggleOptions = () => {
    if (isNarrowScreen) { return null }
    let buttons = [];

    if (metakeys) {
      metakeys.sort().forEach((button, index) => {
        buttons.push(
          <Button
            key={button + index}
            className={visibilitySettings.get(button) === false ? "custom-panel-button bg-transparent" : "custom-panel-button"}
            onClick={() => togglePanels(button)}>
            {translate(button)}
          </Button>
        )
      });
    }

    if (buttons.length > 0) return (  
      <div>
        <div className={`${!isNarrowScreen ? 'left-divider' : ''}`}>
          <div className={`buttons-container`}> 
            {buttons}
          </div>
        </div>
      </div>
    )
  }

  const getPdfExportButton = () => {
    if (isNarrowScreen) { return null }
    return (
      <div style={{ marginLeft: "auto" }}>
        <div className={!isNarrowScreen ? 'left-divider' : ''}>
          <div className="pdfExportButton">
            <HeaderButton
              tooltip="Export PDF"
              icon="fa-file-pdf"
              onClick={() => togglePDFModal(!showPDFModal)}
              customClass="menu-toggle-button far"
            />
          </div>
        </div>
      </div>
    );
  }

  const breadcrumbButtonsStyle = {
    display: "flex",
    flexGrow: 1,
    justifyContent: "flex-start",
    flexDirection: isNarrowScreen ? "column" : "row",
    gap: isNarrowScreen ? "1rem" : "0"
  };

  return (
    <div className="breadcrumb">
      <div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'flex-start', width: '100%'}}>

      <div className="breadcrumb-id-content">
        {getMenuToggleButton()}
        {getTitleAndIcon()}
      </div>

        {item && (
          <div style={{ ...breadcrumbButtonsStyle, display: 'flex', flexWrap: 'nowrap', alignItems: 'stretch', marginLeft: 'auto' }}>
            {minimizedPanels && Object.keys(minimizedPanels).length > 0 && getMinimizedPanelsSelection()}

            {showCustomPanelButton && getGlobalTimeFilter()}

            {showCustomPanelButton && (
              <div>
                <div className={!isNarrowScreen ? 'left-divider' : ''}>
                  <CustomPanelButton activeApp={activeApp} endpoints={endpoints} />
                </div>
              </div>
            )}

            {getPanelToggleOptions()}

            {getPdfExportButton()}
            <PdfExportModal
              pdfName={`${site.split(":")[1]}_${item.text}`}
              showPDFModal={showPDFModal}
              onClick={() => togglePDFModal(!showPDFModal)}
            />

          </div>
        )}
      </div>
    </div>
  );
}