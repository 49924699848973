import React, { useEffect, useContext, useState, useRef } from "react";

import axios from "axios";
import { Loader, Whisper, Tooltip, Popover, Drawer } from "rsuite";
import { PanelMoreButton } from "../moreButtons";

import {
  alphabeticalSort,
  makeAuthUrlWithHeaders,
  acquireToken,
  getBoaURL
} from "../utils";
import { FeedbackModal } from "../modals";
import { translate } from "../languages";
import { TinyMCE } from ".";

import {
  UseAuthenticationContext,
  SiteContext
} from "../context";
import { MsalContext } from "@azure/msal-react";

export default function GeneratedReportsView(props) {
  const { endpoints } = props;

  const { useAuthentication } = useContext(UseAuthenticationContext);
  const { site }              = useContext(SiteContext);
  const msalContext           = useContext(MsalContext);

  const [showFeedbackModal, toggleFeedbackModal]  = useState(false);
  const [ascOrder, setAscOrder]                   = useState(true);
  const [orderBy, setOrderBy]                     = useState("datetime");
  const [reports, setReports]                     = useState();
  const [sortedReports, setSortedReports]         = useState();
  const [loadingReport, setLoadingReport]         = useState();
  const [loadedFile, setLoadedFile]               = useState(); 
  const [selectedGroup, setSelectedGroup]         = useState();
  const [openEdit, setEdit]                       = useState(false);

  const popoverRef = useRef();

  useEffect(()=> { // Fetch reports
    async function fetchAllReports() {
      const listFilesEndpoint = endpoints?.listFiles;
      const readFilesEndpoint = endpoints?.readFiles;
      if (listFilesEndpoint && readFilesEndpoint) {
        if (useAuthentication && msalContext.accounts.length > 0) {
          let url = listFilesEndpoint;
          let headers = {};
          [url, headers] = await makeAuthUrlWithHeaders(
            url, 
            headers, 
            acquireToken(msalContext)
          );
          try {
            await axios.get(url, {headers}).then(response => { setReports(response.data) });
          } catch (e) {
            console.log(e);
          }
        } else { //local
          try {
            await axios.get(listFilesEndpoint).then(response => { setReports(response.data) });
          } catch (e) {
            console.log(e);
          }
        }
      }
    }
    fetchAllReports();
  },[msalContext, useAuthentication, endpoints]);

  useEffect(()=> { // sort reports based on user selections  
    function sort(reports) { // sort reports, date or alphabetical
      if (orderBy === "datetime") {
        if (ascOrder) { return reports.sort((a, b) => Date.parse(b.datetime) - Date.parse(a.datetime)); } 
        else { return reports.sort((a, b) => Date.parse(a.datetime) - Date.parse( b.datetime)); }
      } else {
        if (ascOrder) { return reports.sort((a, b) => alphabeticalSort(b.name, a.name)); }
        else { return reports.sort((a, b) => alphabeticalSort(a.name, b.name)); }
      }
    }
    function sortReports() { //sort reports, latest report in each category of all reports in a single category
      if (Array.isArray(reports) && reports.length > 0  ) {
        let groups = [ ...new Set(reports.map(report => report.group))]; // unique groups
        if (selectedGroup) {
          setSortedReports(sort(reports.filter(report => report.group === selectedGroup)));
        } else {
          let latestReports = [];
          for (let group of groups) {
            latestReports.push(reports.filter(report => report.group === group).sort((a, b) => Date.parse(b.datetime) - Date.parse(a.datetime))[0]);
          }
          setSortedReports(latestReports);
        }
      }
    }
    sortReports();
  },[reports, selectedGroup, ascOrder, orderBy])

  const createReportTable = () => {
    return(
      <div className="generated-reports-table-container">
        <table className="generated-reports-table">
          <thead style={{color: "rgb(255, 255, 255)", backgroundColor: "rgb(12, 84, 126)"}}>
            <tr>
              <th>
                {selectedGroup 
                  ? <Whisper
                      speaker={<Tooltip>{translate("Back to main view")}</Tooltip> }
                      trigger="hover"
                      delayShow={750}
                      placement="top"
                    >
                      <button
                        style={{backgroundColor: "transparent", width:"18px"}}
                        onClick={() => setSelectedGroup(null) }
                      >
                        <i className="fas fa-chevron-left" />
                      </button>
                    </Whisper>
                  : <div style={{width:"18px"}}></div>
                }
              </th>
              
              {selectedGroup 
                ? <th className="pointer" onClick={()=> handleOrderChange({value: "alphabetical"})}>
                    {translate(selectedGroup)} {translate("report")}
                    {getSortIcon("alphabetical")}
                  </th>
                : <th>{translate("Newest report")}</th>
              }

              {endpoints?.saveFiles && <th> {translate("Comment")} </th>}
              {selectedGroup ? <th className="pointer" onClick={()=> handleOrderChange({value: "datetime"})}>
                {translate("Created")}
                {getSortIcon("datetime")}
              </th>
              :
              <th>{translate("Created")}</th>
            }
              {!selectedGroup && <th>{translate("Other reports in category")}</th>}
            </tr>
          </thead>

          <tbody>
            {sortedReports.map((report, index) => (
              <tr key={`generatedReportsMainTableBodyItem-${index}`}>
                <td> 
                  {loadingReport === report.filename && <div className="d-inline-block"><Loader style={{display: "inline-block"}} size="sm" /></div>}
                </td>                   
                <td>
                  <p
                    className="table-clickable"
                    onClick={() => { 
                      fetchSingleReport(report.filename, "show"); 
                      setLoadingReport(report.filename);
                    }}
                  >
                    {report.name}
                  </p>
                </td>
                {endpoints?.saveFiles &&
                  <td>
                    <p
                      className="table-clickable"
                      onClick={() => { 
                        fetchSingleReport(report.filename, "edit"); 
                        setLoadingReport(report.filename);
                      }}
                    >
                      <i className="fas fa-edit"></i>
                    </p>
                  </td>
                }
                <td>
                  <p>
                    {report.datetime}
                  </p>
                </td>
                
                {!selectedGroup && 
                  <td>
                    <Whisper
                      speaker={
                        <Tooltip>
                          {translate("Show all reports in category")} {translate(report.group)}
                        </Tooltip>
                      }
                      trigger="hover"
                      delayShow={750}
                      placement="top"
                    >
                      <p
                        className="table-clickable"
                        onClick={() => setSelectedGroup(report.group)}
                      >
                        {translate(report.group)} - {translate("All reports")}
                      </p>
                    </Whisper>
                  </td>
                }
              </tr>
            ))}
          </tbody>

        </table>
      </div>
    );
  }

  const getSortIcon = (sortType) => { //Function returns icon based on sort status (up/down/default)
    if (sortType === orderBy ) {
      if (ascOrder) { return <i className="ms-1 fas fa-sort-up" />}
      else { return <i className="ms-1 fas fa-sort-down" /> }
    } else {
      return <i className="ms-1 fas fa-sort" />
    }
  }

  const handleOrderChange = (orderByOption) => {
    const newOrderBy = orderByOption.value;
    if (newOrderBy === orderBy) { setAscOrder(!ascOrder); }
    else { setAscOrder(true); }
    setOrderBy(newOrderBy)
  }

  const fetchSingleReport = async(filename, output) => {
    let originalUrl = endpoints?.readFiles + filename;
    let url = endpoints.saveFiles ?  endpoints.saveFiles + filename : originalUrl;
    let headers = { responseType: "blob" };
    if (useAuthentication && msalContext.accounts.length > 0) {
      [url, headers] = await makeAuthUrlWithHeaders(
        url, 
        headers, 
        acquireToken(msalContext)
      );
      originalUrl = getBoaURL() + originalUrl;
    }
    await axios({
      method: 'get',
      url: url,
      headers: headers,
    }).then(response => {  
      if (response.data.fetchOriginal) {
        axios({
          method: 'get',
          url: originalUrl,
          headers: headers,
        }).then(response => {  
          let report = window.URL.createObjectURL(new Blob([response.data], { type: "text/html" }));
          setLoadingReport(null);
          setLoadedFile({ file: response.data, saveUrl: endpoints.saveFiles + filename, filename: filename });
          if (output === "show"){ window.open(report, "_blank").focus() } 
          else { setEdit(true) }
        });
      } else {
        let report = window.URL.createObjectURL(new Blob([response.data], { type: "text/html" }));
        setLoadingReport(null);
        setLoadedFile({ file: response.data, saveUrl: endpoints.saveFiles + filename, filename: filename });
        if (output === "show"){ window.open(report, "_blank").focus() } 
        else { setEdit(true) }
      }
    })
}

/* button with 3 ellipsis that opens settings*/
const moreButton = () => {
  return (
    <Whisper
      placement="autoHorizontalStart"
      ref={popoverRef}
      speaker={
        <Popover>
          <PanelMoreButton
            buttonTooltip = "Give feedback"
            icon = "fa-envelope"
            buttonText = "Panel feedback"
            onClick = {()=> { toggleFeedbackModal(true); popoverRef.current.close(); } }
            panel = "generated_reports"
          />
        </Popover>
      }
      trigger="click"
      preventOverflow
    >
      <div className="more-button-container pointer">
        <em className="fas fa-ellipsis-v" />
      </div>
    </Whisper>
  );
}
  
  const getLoader = () => {
    return (
      <div style={{display: "inline-block", padding: "10px", width: "100%"}}>
        <div><em className="fas fa-spinner me-1" id="loadingIcon"/>{translate("Fetching reports")}</div>
      </div> 
    );
  }

  return (
    <React.Fragment>
      <div className="card item d-flex" style={{ minWidth: "300px" }}>
        <div className="card-title">
          <div className="boa-card-header">{translate("Reports")}</div>
        </div>
        {useAuthentication && moreButton()}
        <div>
          {sortedReports ? createReportTable() : getLoader()}
        </div>
      </div>
      <FeedbackModal
        show={showFeedbackModal}
        title="Generated Reports"
        panel="generated_reports"
        site={site}
        onHide={()=> toggleFeedbackModal(!showFeedbackModal)}
      />
      <Drawer size="full" enforceFocus={false} placement="bottom" backdrop = "static" open={openEdit} onClose={() => setEdit(false)}>
        <Drawer.Header>
          <Drawer.Title>{loadedFile?.filename}</Drawer.Title>
        </Drawer.Header>
        <Drawer.Body>
          {loadedFile && <TinyMCE content={loadedFile.file} saveUrl={loadedFile.saveUrl}/>}
        </Drawer.Body>
      </Drawer>
    </React.Fragment>
  );
}