import React, { useState, useRef, useContext } from "react";
import ReactQuill from "react-quill-new";
import { Modal, Notification, toaster, Loader, Checkbox, Button } from "rsuite";
import { getQuillToolbar } from ".";
import { fetchDataHelper, getUserNameFromEmail } from "../utils";
import { translate } from "../languages";
import { SiteContext, UseAuthenticationContext, NarrowScreenContext } from "../context";
import { MsalContext } from "@azure/msal-react";
import { useIntl } from "react-intl";

const FeedbackModal = (props) => {
  const {
    onHide,
    panel,
    show, 
    title,
    site_info
  } = props;

  const { useAuthentication }     = useContext(UseAuthenticationContext);
  const { isNarrowScreen }        = useContext(NarrowScreenContext);
  const msalContext               = useContext(MsalContext);
  const { site }                  = useContext(SiteContext);
  const intl                      = useIntl();

  const [loading, setLoading] = useState(false);
  const [getCopyOfFeedback, setGetCopyOfFeedback] = useState(false);

  const editorRef = useRef();
  const feedbackSendSuccess = () => {
    toaster.push(
      <Notification
        type="success"
        header={intl.formatMessage({ id: "Feedback sent successfully" })}
        closable
      />,
      { placement: "topEnd" }
    );
    onHide();
  };

  const feedbackSendError = () => {
    toaster.push(
      <Notification
        type="error"
        header={intl.formatMessage({ id: "Something went wrong" })}
        closable
      >
        {intl.formatMessage({ id: "notification_something_wrong_with_feedback_send" })}
      </Notification>,
      { placement: "topEnd" }
    );
  };


  const getFeedbackSenderHTML = (name, email) => {
    return `<hr><p><em>This message was sent by user:</em></p><p>${name}</p><p>${email}</p>`;
  };

  const handleSend = () => {
    const htmlMessage = editorRef.current.getEditor().root.innerHTML;
    const email = useAuthentication ? msalContext?.accounts?.[0]?.username : "local user";
    const name = useAuthentication ? getUserNameFromEmail(email) : site_info?.text;
    const feedbackSender = getFeedbackSenderHTML(name, email);
    const fullMessage = htmlMessage + feedbackSender;
    let postObject;
    let url;

    if (site_info?.jiraTicket) {
      postObject = {
        summary: `${title} - Feedback`,
        description: fullMessage,
        panel: panel,
        parent: site_info.jiraTicket
      };
      url = `/boa/api/v1/jira/create`;     
    } else {
      postObject = {
        html: fullMessage,
        subject: `${title} - ${site} - Feedback`
      };
      if (getCopyOfFeedback) {
        postObject.sendCopyToSender = true;
        postObject.copyToSenderAddress = email;
      }
      url = `/boa/api/v1/${site}/mail`;
    }
  
    if (url) {
      fetchDataHelper({
        url: url,
        method: "POST",
        data: postObject,
        useAuthentication: useAuthentication,
        authContext: msalContext,
        onFetchStart: setLoading(true),
        onFetchEnd: setLoading(false),
        onSuccess: feedbackSendSuccess,
        onError: feedbackSendError
      });
    }
  };

  const getToolbar = () => (
    <div id="toolbar" className="ql-toolbar ql-snow">
      {getQuillToolbar()}
    </div>
  );

  const isButtonDisabled = () => loading;

  const toggleGetCopyOfFeedback = () => setGetCopyOfFeedback(!getCopyOfFeedback);

  const getEmailAddressField = () => {
    if (getCopyOfFeedback) {
      return (
        <p className="feedback-modal__email-address-field">
          &nbsp;&nbsp;
          <i className="fas fa-arrow-right" />
          &nbsp;&nbsp;
          {msalContext?.accounts?.[0]?.username}
        </p>
      );
    } else {
      return null;
    }
  };

  const getCopyOfEmailToggle = () => {
    if (useAuthentication) {
      return (
        <div className="feedback-modal__email-toggle">
          <Checkbox
            checked={getCopyOfFeedback}
            onChange={toggleGetCopyOfFeedback}
          />
          {intl.formatMessage({ id: "Receive copy of feedback email" })}
          {getEmailAddressField()}
        </div>
      );
    }
  };

  return (
    <Modal
      open={show}
      size={isNarrowScreen ? "xs" : "lg"}
      onClose={() => onHide() }
      backdrop="static"
    >
      <Modal.Header>
        <h3>{`${intl.formatMessage({ id: title || panel })} - ${intl.formatMessage({ id: "feedback" })}`}</h3>
      </Modal.Header>

      <Modal.Body>
        {getToolbar()}
        <ReactQuill
          modules={{ toolbar: { container: "#toolbar" } }}
          placeholder={intl.formatMessage({ id: "send_feedback_placeholder" })}
          ref={editorRef}
        />
        {loading && <Loader size="lg" center /> }
        {site_info?.jiraTicket ? null : getCopyOfEmailToggle() }
      </Modal.Body>

      <Modal.Footer>
        <Button
          onClick={() => handleSend()}
          appearance="primary"
          color="green"
          disabled={isButtonDisabled()}
          data-testid="feedback-modal-send-button"
        >
          {site_info?.jiraTicket ? translate("Create issue") : translate("Send feedback")}
        </Button>

        <Button
          appearance="primary"
          color="red"
          onClick={() => onHide() }
          data-testid="feedback-modal-close-button"
        >
          {translate("Cancel")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default FeedbackModal;