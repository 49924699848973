import React, { useState } from "react";
import { PanelGroup, Panel } from "rsuite";
//import { RegistrationForm } from "../forms";
import { LoginButton } from "./";
import { SmartBOA } from "../img";
import { translate } from "../languages";

export default function LoginScreen() {
  
  const [loginHeader, setLoginHeader] = useState(false);
  
  const toggleLoginHeader = (key) => {
    if (key === 1) {
      setLoginHeader(false);
    } else {
      setLoginHeader(true);
    }
  }

  const panelHeader = (text, icon) => {
    return(
      <div>
        <span className = "me-2">
          {translate(text)}
        </span>
        <i className = {icon} />
      </div>
    );
  }

  return(
    <div className="siteSelection">
      <div style={{textAlign: "center"}}>
        <div style={{padding: "20px"}}>
          <img 
            src={SmartBOA}
            alt="Smart BOA logo"
            style={{ width: "150px" }}
          />
        </div>
        <PanelGroup accordion defaultActiveKey={1} onSelect={(eventKey) => toggleLoginHeader(eventKey)}>
          <Panel 
            header={loginHeader && panelHeader("Log in", "fas fa-sign-in-alt")} 
            collapsible 
            eventKey={1}
          >
            <LoginButton />
          </Panel>
          {/* <Panel 
            header={panelHeader("New user?", "fas fa-user-plus")}
            collapsible
            eventKey={2}
          >
            <RegistrationForm />
          </Panel> */}
        </PanelGroup>
        <div className="p-3">
          <div className="d-block ms-2 mt-2">© 2008-{new Date().getFullYear()} ANDRITZ AG</div>
          <div className="d-block ms-2">{translate("All rights reserved")}</div>
        </div>
      </div>
    </div>
  );
}