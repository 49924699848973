import axios from "axios";
import regression from "regression";
import { cloneDeep } from "lodash";
import moment from "moment";
import {
  logErrorIfDevelopmentMode,
  getTimeparams,
  makeAuthUrlWithHeaders,
  removeSeriesTimestamps,
  acquireToken
} from "../utils";

export async function PlotsData(props) { //series, msal, state, useAuthentication) {
  const {
    series,
    msal,
    state,
    useAuthentication,
    params,
    site
  } = props;
 

  const handleData = async() => {
    let seriesData = [];
    const queryParamsArray = [];
    for (let seriesIndex in series) {
      if (Object.keys(series[seriesIndex]).length > 0) {
        const serie = series[seriesIndex];
        const inputParams = embedInputs(serie, params);
        let [url, method, queryParams] = formRequestUrl(serie, state, inputParams, site);
        queryParamsArray.push(queryParams);
        let headers = {};
        if (queryParams.get_inputs ) { continue; } // skip query if get_inputs exists. After query is ready get_inputs is deleted.
        /* Modify request URL in case authentication is used */
        if (useAuthentication) {
          [url, headers] = await makeAuthUrlWithHeaders(
            url, 
            headers, 
            acquireToken(msal)
          )
        }
          try {
            await axios({
              method,
              url,
              data: queryParams,
              headers
            })
            .then(response => {
              const responseCopy = cloneDeep(response.data);

              if (series[seriesIndex]?.parseData?.removeTimestamps) { // removes timestamps
                responseCopy[seriesIndex].data = removeSeriesTimestamps(responseCopy[seriesIndex].data);
              }

              //arearange
              if (series[seriesIndex].seriesprops && series[seriesIndex].seriesprops[0].arearange) {
                let arearangeData = [];
                for (let index in responseCopy[0].data) {
                  arearangeData[index] = [responseCopy[0].data[index][0], responseCopy[0].data[index][1], responseCopy[1].data[index][1]];
                }
                seriesData.push({ ...{data: arearangeData}, ...series[seriesIndex].seriesprops[0].arearange, ...{"sourceIndex": parseInt(seriesIndex)} });	
              } else {
                for (let dataIndex in responseCopy) {
                  // Object Data
                  if (!Array.isArray(responseCopy[dataIndex].data) && typeof responseCopy[dataIndex].data === "object") {
                    const entries = Object.entries(responseCopy[dataIndex].data);
                    for (let i = 0; i < entries.length; ++i) {
                      const object = {
                        "name": entries[i][0],
                        "data": entries[i][1],
                        "sourceIndex": seriesIndex,
                        "seriesName": entries[i][0]
                      };
                      seriesData.push(object);
                    }
                  } 
                  // Array data
                  else {
                    if (series[seriesIndex].seriesprops) {
                      /* if every responseCopy has its own seriesprops combine them */
                      if (responseCopy.length === series[seriesIndex].seriesprops.length) {
                        let seriesName = series[seriesIndex].seriesprops[dataIndex] ? series[seriesIndex].seriesprops[dataIndex].id : responseCopy[dataIndex].name;
                        seriesData.push({...responseCopy[dataIndex], ...series[seriesIndex].seriesprops[dataIndex], ...{"sourceIndex": parseInt(seriesIndex), "seriesName":seriesName} });
                      } else {
                        let seriesName = series[seriesIndex].seriesprops[dataIndex] ? series[seriesIndex].seriesprops[dataIndex].id : responseCopy[dataIndex].name;
                        let seriesprops = series[seriesIndex].seriesprops.find(props => props.id === responseCopy[dataIndex].name);
                        seriesData.push({...responseCopy[dataIndex], ...seriesprops, ...{"sourceIndex": parseInt(seriesIndex), "seriesName":seriesName} });
                      }

                      for (let seriespropsID in series[seriesIndex].seriesprops) {
                        //Add regression if exists
                        if (series[seriesIndex].seriesprops[seriespropsID].regression) {
                          let reg = series[seriesIndex].seriesprops[seriespropsID].regression;
                          let data = regression[reg.method](responseCopy[dataIndex].data).points;
                          let regSerie = {
                            name: `${series[seriesIndex].seriesprops[seriespropsID].name} (${reg.method})`,
                            id: "reg_"+series[seriesIndex].seriesprops[seriespropsID].id,
                            visible: reg.visible ?? true,
                            zIndex: reg.zIndex ?? 1,
                            yAxis: reg.yAxis ?? 0,
                            data: data					  
                          }
                          seriesData.push(regSerie);	
                        }
                      }
                    } else {
                      seriesData.push({...responseCopy[dataIndex], ...{"sourceIndex": parseInt(seriesIndex), "seriesName":responseCopy[dataIndex].name} });
                    }
                  }
                  /* if responseCopy is image add it to its own series */
                  if (responseCopy[dataIndex].image) {
                    seriesData.push({
                      "name": responseCopy[dataIndex].lineId,
                      "data": responseCopy[dataIndex].image,
                      "sourceIndex": seriesIndex,
                      "seriesName": responseCopy[dataIndex].lineId
                    });
                  }
                }
              }
            });
          } catch(e) {
            logErrorIfDevelopmentMode(e);
          }
      }
    }
    return [seriesData, queryParamsArray];
  }

  let [seriesData, queryParamsArray] = await handleData();
  /* TODO: this should use equations function 
    currently, only multiply is working */
  if (seriesData && seriesData.some(data => data.equation)) {
    seriesData = seriesData.map(data => {
      let temp = Object.assign( {}, data);
      if (data.equation) {
        temp.data = data.data.map(value => [value[0], value[1] * data.equation.multiply]);
        return temp;
      } else {
        return data;
      }
    })
  }

  return [seriesData, queryParamsArray];
}

function embedInputs(serie, params) {
  const inputParams = {};
  const queryParams = serie.queryparams;
  if ( params.length > 0 && queryParams.get_inputs) {
      for (let input of queryParams.get_inputs) {
          if (params.some(key => key.name === input.input_name) ) {
            let value = params.find(key => key.name === input.input_name).value;
            let param = input.parameter;
            inputParams[param] = value;
          }
      }
    }
  return inputParams;
}

function formRequestUrl(serie, state, get_inputs = {}, site) {
  /* Base info */
  let url = serie.url || `/boa/api/v1/${site}/history`;
  let method = serie.method || "GET";
  /* Handle POST request when get_inputs are used (submit form) */
  if (method.toUpperCase() === "POST" && serie.queryparams.get_inputs) {
    let params;
    if (serie.queryparams.dataIsArray) { params = {...{ data: [get_inputs] }, ...serie.queryparams }; } 
    else { params = {...{ data: get_inputs }, ...serie.queryparams }; }
    if (get_inputs.setSdt || get_inputs.setEdt) { // datepickers that decides the date range in post request
      if (get_inputs?.setSdt) { // setSdt is used in post request
        params.sdt = moment(get_inputs.setSdt.sdt).utc().format();
        delete params.data.setSdt;
      }
      if (get_inputs?.setEdt ) { // setEdt is used in post request
        params.edt = moment(get_inputs.setEdt.edt).utc().format();
        delete params.data.setEdt;
      }
    }
    if (serie.queryparams.get_inputs?.timestamp) { // timestamp is used in post request
      params.datetime = serie.queryparams.get_inputs.timestamp;
    }
    if (serie.queryparams.tag && params.data) {
      if (serie.queryparams.dataIsArray) { params.data[0].tag = serie.queryparams.tag;} 
      else { params.data.tag = serie.queryparams.tag; }
    }
    /* when submit exists delete get_inputs, if get_inputs exists in params query is prevented, 
     * report item needs to send those inputs first or get_inputs would be empty and post request would be sent without data object
     */
    if (get_inputs.submit) { delete params.get_inputs; }
    return [url, method, params];
  }

  /* Handle other requests (GET and POST) */
  let params = { ...get_inputs, ...serie.queryparams};
   if (serie?.queryparams) {
    /* resolution*/
    if (state.resolution) { params.resolution = state.resolution; }
    /* sdt & edt either from time window or selected value */
    if (params.setSdt || params.setEdt) { // datepickers that decides the date range
      if (params.setSdt) { // setSdt is used in inputs
        params.sdt = moment(params.setSdt.sdt).utc().format();
        delete params.setSdt;
      }
      if (params.setEdt) { // setEdt is used in inputs
        params.edt = moment(params.setEdt.edt).utc().format();
        delete params.setEdt;
      }
    } else if (serie.queryparams.window === "month" || serie.queryparams.window === "lastMonth") { // date picker (month)
      const date = state.dates ? new Date(state.dates.sdt) : new Date();
      const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
      const lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      params.sdt = firstDayOfMonth.toISOString();
      params.edt = lastDayOfMonth.getTime() > new Date().getTime() ? new Date().toISOString() : lastDayOfMonth.toISOString();
    } else if (state.dates) { //if dates exists should be used instead of selectedTimeWindow, date picker (range)
      params.sdt = state.dates.sdt;
      params.edt = state.dates.edt;
    } else if (state.selectedTimeWindow) { //selectedTimeWindow is used if dates does not exist, time buttons
      const dates = getTimeparams(state.selectedTimeWindow);
      let newSdt = new Date(dates.sdt).setSeconds(0,0);
      let newEdt = new Date(dates.edt).setSeconds(0,0);
      params.sdt = new Date(newSdt).toISOString();
      params.edt = new Date(newEdt).toISOString();
    } else if (serie.queryparams.window) { // no time buttons, each query in series has different window { dates: undefined, resolution: undefined, selectedTimeWindow: undefined }
      const dates = getTimeparams(serie.queryparams.window);
      let newSdt = new Date(dates.sdt).setSeconds(0,0);
      let newEdt = new Date(dates.edt).setSeconds(0,0);
      params.sdt = new Date(newSdt).toISOString();
      params.edt = new Date(newEdt).toISOString();
    }
    delete params.window;
    /* delete get_inputs after they are added to params */
    if (serie.queryparams.get_inputs && params.sdt) { delete params.get_inputs; }
    /* future window (in seconds) that is added to the end date if it needs to be in the future */
    if (params.futureWindow) {
      let getHours = new Date(params.edt).getHours(); // current hours
      let newHours = new Date(params.edt).setHours(getHours + params.futureWindow / 60 / 60);  // hours to be added in date object
      let newDate = new Date(newHours).toISOString(); // create new date object with added hours
      params.edt = newDate; // replace edt with new date
      delete params.futureWindow; // remove object so its not included in query
    }
  }
  if (params.calcs) { // post method where calcs is used, query params are not needed in url query
    return [url, method, params];
  } else { // get method where all query params are added to the url query
    url = `${url}?${Object.keys(params).map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(params[key])).join("&")}`;
    return [url, method, params];
  }
}


export async function fetchNonSeriesData(props) {
  const {
    series,
    useAuthentication,
    msal,
    state
  } = props;
  
    series.method = series.method || "GET"; // if no method exists -> GET

    let [url, method] = formRequestUrl(series, state);
    let headers = {};
  
    if (useAuthentication) {
      [url, headers] = await makeAuthUrlWithHeaders(
        url,
        headers,
        acquireToken(msal)
      );
    }

    let nonSeriesData;

    try {
      await axios({
        method,
        url,
        headers
      })
      .then((response) => {
        nonSeriesData = response.data;
      })
    } catch(e) {
      logErrorIfDevelopmentMode(e);
    }
    return nonSeriesData;
}