import React from "react";

import { Checkbox } from "rsuite";
import { isFunction } from "lodash";

import { translate } from "../languages";

export default function CookieBanner(props) {
  const {
    cookiesAccepted,
    onAccept,
    opacity
  } = props;

  /** URL to Andritz AG cookie policy page */
  const ANDRITZ_COOKIE_POLICY_URL = "https://www.andritz.com/group-en/cookies";
  
  function handleAccept() {
    if (isFunction(onAccept)) {
      onAccept();
    }
  }

  let renderContent;
  if (!cookiesAccepted) {
    renderContent = (
      <div
        className={"cookie-banner " + opacity}
        data-testid="cookie-banner-container"  
      >

        <p className="cookie-banner-top-text">
          {translate("Smart BOA uses cookies and collects user data, but in a minimal sense. No third-party cookies are utilized. By continuing the usage of Smart BOA, you accept the usage of these cookies and data collection.")} 
        </p>

        <div className="cookie-banner-bottom-text">
          <p>
            {translate("Read more about how Smart BOA uses cookies")}:&nbsp;
          </p>

          <p
            className="cookie-banner-link"
            onClick={() => window.open(ANDRITZ_COOKIE_POLICY_URL)}
          >
            {translate("Andritz cookie policy")}
          </p>
        </div>

        <div className="cookie-banner-options-container">
          <div className="cookie-banner-checkbox-container">
            <Checkbox
              defaultChecked
              disabled
            >
              {translate("Necessary")}
            </Checkbox>
          </div>

          <div className="cookie-banner-buttons-container">
            <button
              className="btn btn-primary"
              onClick={() => handleAccept()}
              data-testid="cookie-banner-accept-button"
            >
              OK
            </button>
          </div>
        </div>
      </div>
    );
  } else {
    renderContent = null;
  }

  return renderContent;
}
