import React from "react";
import { Modal, Button } from "rsuite"
import { isFunction } from "lodash";
import { translate } from "../languages";

export default function AboutModal(props) {
  const {
    onClose
  } = props;

  const year = new Date().getFullYear();
  const version = process.env.REACT_APP_VERSION;

  function handleClose() {
    if (isFunction(onClose)) {
      onClose();
    }
  }

  return(
    <React.Fragment>
      <Modal.Header>
        <h3>
          {translate("About Smart BOA")}
        </h3>
      </Modal.Header>

      <Modal.Body data-testid="about-modal-body">
        <div className="row w-100 mb-5 mt-5">

          <div className="col-6 text-end">
            <img width="100px" src="https://boa.cc.andritz.com/media/uploads/Capture.PNG" alt="logo"/>
          </div>

          <div className="col-6 align-self-center"> 

            <div className="d-block">
              Smart BOA v.{version}
            </div>
            <div className="d-block">
              <a href="mailto:smart.boa@andritz.com">smart.boa@andritz.com</a>
            </div>

            <div className="d-block mt-2">
              © 2008-{year} ANDRITZ AG 
            </div>
            <div className="d-block">
              All Rights reserved
            </div>
            <div className="d-block">
              <a href="https://www.andritz.com/group-en/privacy-policy/imprint" target="_blank" rel="noreferrer">Imprint</a>
            </div>

          </div>
        </div>

        <div className="mt-3 mb-3">
          <h3 className="mb-1">Privacy Policy</h3>
          <p>
            ANDRITZ recognizes the importance of protecting the personal privacy of its customers, potential customers, employees,
            potential employees, investors, suppliers, consortium partners and website visitors. This data privacy declaration
            describes the information that may be collected by ANDRITZ legal entities, through our websites, through our locations
            and through your communications with us. It also provides information on your rights and the choices you can make
            regarding our use of your personal information and how we protect it.
          </p>
          <p>
            SMART BOA platform collects anonymous user data which can not be linked to anyone. Any user email information is
            stored safety and according to GDPR policies.
          </p>
          <p>
            For more information, please visit:<br/>
            <a href="https://www.andritz.com/group-en/privacy-policy" target="_blank" rel="noreferrer">
              https://www.andritz.com/group-en/privacy-policy
            </a>.
          </p>
        </div>
        
        <div className="mt-2 mb-3">
          <h3 className="mb-1">Cookies</h3>
          <p>
            ANDRITZ wants to offer you the best online experience. Hence, we use cookies to analyze your activities on our website
            as well as your internet and app usage. We organize our cookie functionalities based on current data processing laws.
          </p>
          <p>
            For more information, please visit:<br/> 
            <a href="https://www.andritz.com/group-en/cookies" target="_blank" rel="noreferrer">
              https://www.andritz.com/group-en/cookies
            </a>.
          </p>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button
          data-testid="about-close-button"
          onClick={handleClose}
          appearance="primary" 
          color="red"
          style={{marginTop: "10px"}}
          > {translate("Close")}
        </Button>
      </Modal.Footer>
    </React.Fragment>
  );
}
